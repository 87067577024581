<template>
  <Modal ref="modal" :title="title">
    <div>
      <div class="row">
        <div class="col-sm-6">
          <h3>{{ contacts && contacts.length > 1 ? 'Kandidaten' : 'Kandidaat' }}</h3>
          <div v-for="contact in contacts" :key="contact.id" class="tw-flex tw-align-center">
            <input
              v-show="contacts.length > 1"
              type="checkbox"
              :value="contact.id"
              v-model="contactsToSendSmsTo"
              :disabled="!contact.mobile"
              class="!tw-mr-1 !tw-my-0 tw-self-center"
            >
            <label
              v-if="contact && contact.display_name"
              class="!tw-mb-0"
            >
              {{ contact.display_name }}
              <a
                v-if="contact && contact.mobile"
                :href="`tel:${contact.mobile}`"
              >
                M. {{ contact.mobile }}
              </a>
               <span v-else class="input-error">(geen gsm-nummer)</span>
            </label>
          </div>
          <template v-if="!activityIsOutgoingPhoneCall">
            <h3 class="tw-mt-4">Herinnering</h3>
            <div class="form-group">
              <label><input type="checkbox" v-model="sendReminder"> Verstuur extra herinnering</label>
            </div>
            <select v-model="reminderPeriod" :disabled="!sendReminder" class="form-control tw-mb-2">
              <option value="30">30min voor bezoek</option>
              <option value="60">1u voor bezoek</option>
              <option value="120">2u voor bezoek</option>
              <option value="240">4u voor bezoek</option>
              <option value="360">6u voor bezoek</option>
              <option value="720">12u voor bezoek</option>
              <option value="1440">24u voor bezoek</option>
            </select>
            <span v-if="sendReminder">Herinnering versturen op {{ reminderDay }} om {{ reminderTime }}</span>
          </template>
        </div>
        <div class="col-sm-6">
          <div class="tw-flex tw-justify-between tw-gap-2 tw-items-center">
            <h3>Sms</h3>
            <div class="tw-flex tw-gap-1 tw-items-center">
              <div>
                <input v-model="selectedLanguage" type="radio" id="lang_nl" value="nl" />
                <label for="lang_nl" class="tw-ml-0.5">NL</label>
              </div>
              <div>
                <input v-model="selectedLanguage" type="radio" id="lang_fr" value="fr" />
                <label for="lang_fr" class="tw-ml-0.5">FR</label>
              </div>
              <div>
                <input v-model="selectedLanguage" type="radio" id="lang_en" value="en" />
                <label for="lang_en" class="tw-ml-0.5">EN</label>
              </div>
            </div>
          </div>
          <div v-if="!activityIsOutgoingPhoneCall" class="tw-flex tw-gap-x-1 tw-mb-2">
            <button
              type="button"
              :class="['btn btn-xs', ['visit', 'prospect'].includes(this.messageType) ? 'btn-primary' : 'btn-default']"
              @click="setMessageType((property && property.status) === 1 ? 'prospect' : 'visit')"
            >
              Bezoek pand
            </button>
            <button
              type="button"
              :class="['btn btn-xs', this.messageType === 'office' ? 'btn-primary' : 'btn-default']"
              @click="setMessageType('office')"
            >
              Bezoek kantoor
            </button>
          </div>
          <textarea class="form-control" rows="5" v-model="message"/>
        </div>
      </div>

      <div class="row margin-top-20">
        <div class="col-sm-12 alert alert-danger" v-if="!isValidMessage">
          De sms-boodschap moet minstens 10 en maximaal 160 karakters hebben.
        </div>
      </div>
    </div>
    <div slot="footer">
      <button :disabled="!isValid" @click="send" class="btn btn-primary" type="button">Versturen</button>
      <button class="btn btn-link" data-dismiss="modal" type="button">Annuleren</button>
    </div>
  </Modal>
</template>

<script>
import moment from 'moment'
import Modal from '@/components/iam/Modal'
import { mapGetters } from 'vuex'
import { errorModal, startLoadingModal, successModal } from '@/modalMessages'
import { createMessage } from '@/services/messages'
import { getPropertyOffices } from '@/services/properties'
import { getProjectOffices } from '@/services/projects'

export default {
  name: 'SMSConfirmationModal',
  components: { Modal },
  props: ['lead'],
  data () {
    return {
      sendReminder: false,
      reminderPeriod: 60,
      message: '',
      selectedLanguage: 'nl',
      activity: {},
      messageType: 'visit',
      office: null,
      contactsToSendSmsTo: []
    }
  },
  constants: {
    OUTGOING_PHONE_CALL_ACTIVITY_TYPE_ID: 126
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById', 'getProjectById']),
    ...mapGetters('organization', ['getCollaboratorById']),

    property () {
      if (this.lead.entity_type === 'property') {
        return this.getPropertyById(this.lead.entity)
      }
      if (this.lead.entity_type === 'project') {
        return this.getProjectById(this.lead.entity)
      }
      return null
    },
    activityIsOutgoingPhoneCall () {
      return this.activity?.type?.id === this.OUTGOING_PHONE_CALL_ACTIVITY_TYPE_ID
    },
    reminderDay () {
      const d = moment(this.activity.start_date)
      d.subtract(this.reminderPeriod, 'minutes')
      return d.format('dddd D MMMM')
    },
    reminderTime () {
      const d = moment(this.activity.start_date)
      d.subtract(this.reminderPeriod, 'minutes')
      return d.format('HH:mm')
    },
    isValidMessage () {
      return this.message.length > 10 && this.message.length < 300
    },
    isValid () {
      return this.contactsToSendSmsTo.length && this.isValidMessage
    },
    collaborator () {
      if (this.lead) {
        return this.getCollaboratorById(this.lead.collaborator)
      }
      return {}
    },
    title () {
      if (this.activityIsOutgoingPhoneCall) {
        return 'Uitgaande sms sturen'
      }
      return 'Sms-bevestiging sturen'
    },
    contacts () {
      return this.lead.candidates
    }
  },
  watch: {
    contactsToSendSmsTo () {
      this.setMessageValue()
    },
    messageType () {
      this.setMessageValue()
    },
    selectedLanguage () {
      this.setMessageValue()
    },
    property (property) {
      this.setMessageType(property?.status === 1 ? 'prospect' : 'visit')
    }
  },
  methods: {
    async sendMessageForContact (contactId, smsDescription) {
      const data = {
        type: 1, // Type SMS
        to: contactId,
        message: this.message,
        send_at: moment().format('YYYY-MM-DDTHH:mm'),
        activity: this.activity.id,
        activity_message_type: this.activityIsOutgoingPhoneCall ? 0 : 1 // 0 = other, 1 = confirmation
      }
      const response = await createMessage(data)
      if (this.sendReminder) {
        data.message = `Herinnering: ${data.message}`
        data.send_at = moment(this.activity.start_date).subtract(this.reminderPeriod, 'minutes').format('YYYY-MM-DDTHH:mm')
        data.activity_message_type = 2 // 2 = reminder
        await createMessage(data)
        successModal('SMS bevestiging is verstuurd en een herinnering zal worden verstuurd', true)
      } else {
        successModal(`${smsDescription} is verstuurd`, true)
      }
      this.hide()
      return response
    },

    async send () {
      let smsDescription = 'Sms bevestiging'
      if (this.activityIsOutgoingPhoneCall) {
        smsDescription = 'Uitgaande sms'
      }
      try {
        startLoadingModal(`${smsDescription} versturen...`)
        for await (const contactId of this.contactsToSendSmsTo) {
          await this.sendMessageForContact(contactId, smsDescription)
        }
        this.$emit('sms-sent', this.message)
        this.hide()
      } catch (e) {
        console.error(e)
        errorModal(`Er ging iets mis bij het versturen van de ${smsDescription.toLowerCase()}: ${e.message}`)
      }
    },
    async loadPrimaryOffice () {
      const response = this.lead.entity_type === 'property'
        ? await getPropertyOffices(this.lead.entity)
        : await getProjectOffices(this.lead.entity)
      this.office = response?.data?.results?.[0]
      return response
    },
    show () {
      this.loadPrimaryOffice()
      this.selectedLanguage = this.contacts?.[0]?.language
      this.contactsToSendSmsTo = this.contacts.filter(contact => contact.mobile).map(contact => contact.id)
      this.setMessageValue()
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    setMessageType (type) {
      this.messageType = type
    },
    setMessageValue () {
      let messages = {}
      if (this.activityIsOutgoingPhoneCall) {
        messages = {
          nl: `Beste, U heeft ons gecontacteerd voor een bezichtiging van het pand ${this.property?.address}. Indien u een bezoek wenst, kan u mij altijd contacteren op ${this.collaborator?.mobile}. Mvg, ${this.collaborator?.first_name}`,
          fr: `Bonjour, vous m’avez contacté pour visiter le bien à ${this.property?.address}. Si vous souhaitez une visite, vous pouvez toujours me contacter au ${this.collaborator?.mobile}.`,
          en: `Hello, you have contacted us to visit the property at ${this.property?.address}. If you would like a visit, you can always contact me at ${this.collaborator?.mobile}. Kind regards`
        }
      } else {
        const momentInstance = moment(this.activity?.start_date)
        const time = momentInstance.format('HH:mm')
        const dayDateNl = momentInstance.locale('nl').format('dddd D MMMM')
        const dayDateFr = momentInstance.locale('fr').format('dddd D MMMM')
        const dayDateEn = momentInstance.locale('en').format('dddd D MMMM')

        let salutation = ''
        console.log(this.contactsToSendSmsTo.length)
        if (this.contactsToSendSmsTo.length === 1) {
          console.log(this.contactsToSendSmsTo[0])
          salutation = ` ${this.contacts.find(c => c.id === this.contactsToSendSmsTo[0]).first_name}`
        }
        console.log(salutation)

        const messagesProspect = {
          nl: `Beste${salutation}, hierbij bevestig ik onze afspraak aan uw eigendom in de ${this.property?.address}. Het bezoek gaat door op ${dayDateNl} om ${time} - Indien verhinderd: ${this.collaborator?.first_name}, ${this.collaborator?.mobile}. Antwoord niet op deze SMS maar bel naar ${this.collaborator?.mobile}`,
          fr: `Bonjour${salutation}, par la présente je vous confirme notre rendez-vous à votre propriété situé ${this.property?.address}. La visite aura lieu le ${dayDateFr} à ${time}. - En cas d'empêchement, ne répondez pas à ce message, mais à ${this.collaborator?.first_name}, ${this.collaborator?.mobile}.`,
          en: `Hello${salutation}, I hereby confirm our appointment at your property, located at ${this.property?.address}. The visit will take place on ${dayDateEn} at ${time}. - In case you are unable to attend, do not reply to this message, but call ${this.collaborator?.first_name}, ${this.collaborator?.mobile}.`
        }
        const messagesVisit = {
          nl: `Beste${salutation}, hierbij bevestig ik uw bezoek aan het pand te ${this.property?.address}. Het bezoek gaat door op ${dayDateNl} om ${time} - Indien verhinderd: ${this.collaborator?.first_name}, ${this.collaborator?.mobile}. Antwoord niet op deze SMS maar bel naar ${this.collaborator?.mobile}`,
          fr: `Bonjour${salutation}, par la présente je vous confirme votre visite du bien situé ${this.property?.address}. La visite aura lieu le ${dayDateFr} à ${time}. - En cas d'empêchement, ne répondez pas à ce message, mais à ${this.collaborator?.first_name}, ${this.collaborator?.mobile}.`,
          en: `Hello${salutation}, I hereby confirm your visit to the property located at ${this.property?.address}. The visit will take place on ${dayDateEn} at ${time}. - In case you are unable to attend, do not reply to this message, but call ${this.collaborator?.first_name}, ${this.collaborator?.mobile}.`
        }
        const messagesOffice = {
          nl: `Beste${salutation}, hierbij bevestig ik onze afspraak i.v.m. het pand te ${this.property?.address}. De afspraak gaat op ${dayDateNl} om ${time} door bij ons op kantoor: ${this.office?.street} ${this.office?.number} ${this.office?.zip_code} ${this.office?.city_nl}. - Indien verhinderd, antwoord niet op deze sms, maar bel naar ${this.collaborator?.first_name}, ${this.collaborator?.mobile}`,
          fr: `Bonjour${salutation}, par la présente je vous confirme notre rendez-vous concernant le bien situé ${this.property?.address}. Le rendez-vous aura lieu le ${dayDateFr} à ${time} dans nos bureaux: ${this.office?.street} ${this.office?.number} ${this.office?.zip_code} ${this.office?.city_fr}. - En cas d'empêchement, appelez le ${this.collaborator?.mobile} (${this.collaborator?.first_name}).`,
          en: `Hello${salutation}, I hereby confirm our appointment regarding the property located at ${this.property?.address}. The appointment will take place on ${dayDateEn} at ${time} at our office: ${this.office?.street} ${this.office?.number} ${this.office?.zip_code} ${this.office?.city_en}. - In case you are unable to attend, call ${this.collaborator?.mobile} (${this.collaborator?.first_name}).`
        }
        if (this.messageType === 'visit') messages = messagesVisit
        else if (this.messageType === 'prospect') messages = messagesProspect
        else messages = messagesOffice
      }
      this.message = messages[this.selectedLanguage]
    }
  }
}
</script>
