<template>
  <div v-if="count" class="tw-flex tw-items-center tw-px-[5px] tw-py-[1px] tw-text-xs tw-rounded tw-bg-red-500 tw-text-white">
    <span class="">
      No-shows: {{ count }}
    </span>
  </div>
</template>

<script>
import { getContactNoShowsCount } from '@/services/contacts'

export default {
  name: 'ContactNoShowsCounter',
  props: {
    contactId: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      count: 0
    }
  },
  created () {
    this.fetchNoShowCount()
  },
  methods: {
    async fetchNoShowCount () {
      const response = await getContactNoShowsCount(this.contactId)
      this.count = response?.data?.count
      return response
    }
  }
}
</script>
