<template>
  <div>
    <img v-show="!isLoading" :src="image" ref="dropzone" class="img-responsive animated fadeIn">
    <img v-show="isLoading" src="@/assets/img/loader.gif" class="img-responsive">
  </div>
</template>

<script>
import Dropzone from 'dropzone'
import PropertyDummy from '@/assets/img/property_dummy.gif'

import { errorModal } from '@/modalMessages'
import { getCookie } from '@/utils/helpers'

export default {
  name: 'ImageDropzone',
  props: ['url', 'imageUrl'],
  data () {
    return {
      dropzone: null,
      previousImageSourceUrl: null,
      imageSourceUrl: null,
      isLoading: true
    }
  },
  mounted () {
    this.imageSourceUrl = this.imageUrl
    this.setup()
  },
  computed: {
    image () {
      return this.imageSourceUrl || PropertyDummy
    }
  },
  watch: {
    url () {
      this.setup()
    },
    imageUrl () {
      this.imageSourceUrl = this.imageUrl
    }
  },
  methods: {
    setup () {
      if (!this.dropzone) {
        this.dropzone = new Dropzone(
          this.$refs.dropzone,
          {
            url: this.url,
            headers: {
              'X-CSRFToken': getCookie('csrftoken')
            }
          }
        )
      }
      this.dropzone.on('success', (file, response) => {
        this.imageSourceUrl = response.thumbnail
        this.isLoading = false
      })
      this.dropzone.on('error', () => {
        this.imageSourceUrl = this.previousImageSourceUrl
        this.isLoading = false
        errorModal('Er ging iets fout bij het uploaden van deze afbeelding. Gelieve nogmaals te proberen.')
      })
      this.dropzone.on('addedfile', () => {
        this.previousImageSourceUrl = this.imageSourceUrl
        this.imageSourceUrl = null
        this.isLoading = true
      })
      this.isLoading = false
    }
  }
}
</script>
