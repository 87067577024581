<template>
  <BaseModal ref="modal" title="Nieuw bod" max-width="tw-max-w-xl" @hide="onHide">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="createBidForm"
      @submit="submit"
    >
      <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
        <FormulateInput
          type="autocomplete"
          auto-complete-type="contact"
          :multiple="true"
          name="contacts"
          label="Kandidaat-kopers"
          placeholder="Selecteer contacten"
          validation="required"
          data-lpignore="true"
          outer-class="tw-my-0"
        >
          <template #label="{ label, id, classes }">
            <div class="tw-flex tw-justify-between">
              <label :for="id" :class="classes.label" v-text="label" class="formulate-label" />
              <button
                type="button"
                title="Nieuwe eigenaar"
                class="link tw-font-semibold"
                @click="showContactCreateModal"
              >
                <i class="far fa-user-plus" /> Nieuw contact
              </button>
            </div>
          </template>
        </FormulateInput>
        <FormulateInput
          type="number"
          name="amount"
          label="Bod"
          placeholder="Bod"
          lang="nl"
          step="0.01"
          validation="bail|required|number|min:0"
          data-lpignore="true"
          outer-class="tw-my-0"
        />

        <FormulateInput
          type="date"
          name="bid_date"
          label="Biedingsdatum"
          placeholder="YYYY-MM-DD"
          :value="today.date"
          validation="bail|required|date:YYYY-MM-DD"
          data-lpignore="true"
          outer-class="tw-my-0"
        />
        <FormulateInput
          type="date"
          name="valid_until_date"
          label="Geldig tot"
          placeholder="YYYY-MM-DD"
          :min="values.bid_date"
          :validation="[
            ['bail'],
            ['optional'],
            ['date', 'YYYY-MM-DD'],
            ['after', values.bid_date]
          ]"
          data-lpignore="true"
          outer-class="tw-my-0"
        />
      </div>

      <FormulateInput
        type="textarea"
        name="comment"
        placeholder="Opmerking"
        :input-class="['tw-h-24']"
      >
        <template #label="{ id, classes }">
          <label :for="id" :class="classes.label">
            Opmerking
          </label>

          <Tooltip class="tw-ml-2 tw-inline-block">
            <i class="fas fa-info-circle" />
            <template #popper>
              Geef bijkomende info mee: roerende goederen, excl of incl garage, berging, staanplaats,...
            </template>
          </Tooltip>
        </template>
      </FormulateInput>

      <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
        <FormulateInput
          type="select"
          name="suspensive_condition"
          label="Opschortende voorwaarde"
          placeholder="Selecteer een voorwaarde"
          :options="suspensiveConditions"
          outer-class="tw-my-0"
        />
        <FormulateInput
          type="file"
          name="contact_confirmation_file"
          label="Bijlage uploaden (bieder)"
          :uploader="uploadFile"
          :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
          :file-class="['tw-h-10 !tw-mt-1']"
          element-class="formulate-input-element formulate-input-element--file"
          wrapper-class="formulate-input-wrapper"
          outer-class="formulate-input !tw-my-0"
        >
          <template #label="{ id, classes }">
            <label :for="id" :class="classes.label">
              Bijlage uploaden (bieder)
            </label>

            <Tooltip class="tw-ml-2 tw-inline-block">
              <i class="fas fa-info-circle" />
              <template #popper>
                Voeg de mail of de (tegen)getekende aankoopbelofte toe
              </template>
            </Tooltip>
          </template>

          <template #file="context">
            <BaseFileUploadDisplay
              v-bind="context"
              :show-remove-file="true"
            />
          </template>
        </FormulateInput>
      </div>

      <FormulateInput
        type="checkbox"
        name="is_confirmed_in_writing"
        label="Dit bod is schriftelijk ontvangen"
      />

      <FormulateErrors class="tw-text-right" />

      <div class="tw-flex tw-justify-end">
        <FormulateInput
          type="submit"
          :disabled="isLoading"
        >
          <i
            :class="[
              'fas tw-mr-2',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-gavel'
            ]"
          />
          Bod registreren
        </FormulateInput>
      </div>
    </FormulateForm>

    <ContactCreateModal
      ref="contactCreateModal"
      :redirect="false"
      @success="newOwnerCreated"
    />
  </BaseModal>
</template>

<script>
import { Tooltip } from 'floating-vue'
import { successModal } from '@/modalMessages'
import { parseFileObj, today } from '@/utils/helpers'

import ContactCreateModal from '@/components/contacts/ContactCreateModal'

import { getSuspensiveConditions } from '@/services/apiService'
import {
  uploadFile,
  createPropertyFile,
  createPropertyBid
} from '@/services/properties'

export default {
  name: 'PropertyBidCreateModal',
  components: {
    Tooltip,
    ContactCreateModal
  },
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    },
    leadId: {
      type: [Number, String],
      required: false
    }
  },
  constants: {
    today
  },
  data () {
    return {
      values: {
        is_confirmed_in_writing: false
      },
      suspensiveConditions: []
    }
  },
  methods: {
    showContactCreateModal () {
      this.$refs.contactCreateModal.show()
    },
    newOwnerCreated (contact) {
      const { type, company_name, first_name, last_name } = contact
      const display_name = type === 'B' ? company_name : `${first_name} ${last_name}`
      this.$set(this.values, 'contacts', [...this.values.contacts, { display_name, ...contact }])
      return contact
    },
    onHide () {
      this.values = {
        is_confirmed_in_writing: false
      }
    },

    async show (contacts = []) {
      const response = await this.fetchSuspensiveConditions()
      if (contacts.length) {
        this.$set(this.values, 'contacts', contacts)
      }
      this.$refs.modal.show()
      return response
    },
    async fetchSuspensiveConditions () {
      const response = await getSuspensiveConditions()
      const conditions = response.data?.results || []
      this.suspensiveConditions = conditions.map(({ id, name }) => {
        return { label: name, value: id }
      })
      return response
    },

    async uploadFile (file, progress) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(33)
        const { key, filename } = (await uploadFile(this.propertyId, formData)).data
        progress(66)
        const payload = { key, filename }
        const response = await createPropertyFile(this.propertyId, payload)
        progress(99)
        const parsedFile = parseFileObj(response.data)
        progress(100)

        return [parsedFile]
      } catch (error) {
        this.$formulate.handle(error, 'createBidForm')
      }
    },

    async submit (data) {
      try {
        const { contact_confirmation_file, contacts, ...payload } = data
        if (contacts?.length) {
          payload.contacts = contacts.map(contact => contact.id)
        }
        if (contact_confirmation_file?.length) {
          payload.contact_confirmation_file = contact_confirmation_file[0]?.[0]?.id
        }

        if (this.leadId) {
          payload.match = this.leadId
        }

        const response = await createPropertyBid(this.propertyId, payload)
        successModal('Bod is succesvol aangemaakt')
        this.$emit('bidCreated')
        this.$refs.modal.hide()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'createBidForm')
        throw error
      }
    }
  }
}
</script>
