var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Lead toewijzen","max-width":"tw-max-w-4xl"}},[_c('p',[_vm._v("Aan welke medewerker wens je deze lead toe te wijzen? Je zal automatisch als "),_c('strong',[_vm._v("volger")]),_vm._v(" aan de lead worden gekoppeld. ")]),_c('FormulateForm',{attrs:{"name":"assignmentForm"},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"collaborator","name":"collaborator","label":"Medewerker","placeholder":"Selecteer medewerker","validation":"required"},on:{"input":_vm.processBuyer}}),(_vm.isIntakeReferralEnabled && _vm.isIntakeLead)?[_c('fieldset',{staticClass:"tw-m-0 tw-px-4 tw-border-gray-cc tw-rounded"},[_c('legend',{staticClass:"tw--mx-1 tw-my-0 tw-px-1 tw-w-auto tw-font-bold tw-text-sm tw-text-tg-color"},[_vm._v(" Aanbreng (intakelead) ")]),_c('div',{staticClass:"tw-flex tw-flex-col sm:tw-flex-row tw-gap-2 tw-my-4"},_vm._l((_vm.referralOptions),function(option){return _c('div',{key:option.id,staticClass:"w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.values.referral_yes_no),expression:"values.referral_yes_no"}],staticClass:"tw-peer tw-sr-only",attrs:{"id":option.id,"type":"radio"},domProps:{"value":option.value,"checked":_vm._q(_vm.values.referral_yes_no,option.value)},on:{"change":[function($event){return _vm.$set(_vm.values, "referral_yes_no", option.value)},_vm.setSubRadioButtonFields]}}),_c('label',{staticClass:"\n                  tw-flex tw-justify-center\n                  tw-flex-wrap tw-bg-white tw-border tw-px-2\n                  tw-border-gray-300 tw-rounded tw-text-grey-2\n                  tw-text-sm tw-cursor-pointer focus:tw-outline-none\n                  hover:tw-bg-gray-50 peer-checked:tw-text-black\n                  peer-checked:tw-border-black\n                  peer-checked:tw-font-bold\n                  tw-w-full tw-py-2\n                ",attrs:{"for":option.id}},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(option.label))])])])}),0),(_vm.values.referral_yes_no === 'referral_no')?_c('FormulateInput',{attrs:{"type":"radio","name":"referral_no_selection","options":{
              referral_no: 'Geen aanbreng/inkoop (spontane aanmelding klant, inkoop leidinggevende in exclusief gebied)',
              referral_by_lead: 'Inkoop van mezelf als leidinggevende (100% intakecommissie op transactie)'
            },"validation-messages":{
              required: 'Selecteer een optie.'
            },"validation":"required","outer-class":"tw-my-0","label-class":['tw-text-xs']}}):_vm._e(),(_vm.values.referral_yes_no === 'referral_yes')?_c('FormulateInput',{attrs:{"type":"radio","name":"referral_yes_selection","options":{
              referral_by_collaborator: 'Aanbreng van mezelf aan bovenstaande medewerker (50% intakecommissie op transactie)',
              referral_by_team: 'Aanbreng van een team (50% intakecommissie op transactie, verdeeld over de medewerkers hieronder)'
            },"validation":"bail|required","validation-messages":{
              required: 'Selecteer een optie.'
            },"outer-class":"tw-my-0","label-class":['tw-text-xs']},on:{"input":_vm.processReferralByCollaborator}}):_vm._e(),(_vm.values.referral_yes_selection === 'referral_by_team')?_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"collaborator","multiple":true,"name":"referrers","label":"Aanbrengers","validation":"bail|required","placeholder":"Selecteer medewerker","outer-class":"md:tw-w-1/3 tw-mb-4"},on:{"input":_vm.processReferralByTeam}}):_vm._e()],1),(_vm.values.referral_no_selection || _vm.values.referral_yes_selection)?_c('fieldset',{staticClass:"tw-m-0 tw-mt-4 tw-px-4 tw-border-gray-cc tw-rounded"},[_c('legend',{staticClass:"tw--mx-1 tw-my-0 tw-px-1 tw-w-auto tw-font-bold tw-text-sm tw-text-tg-color"},[_vm._v(" Poules ")]),_c('div',_vm._l((_vm.pouleData),function(poule){return _c('div',{key:poule.collaborator.id,staticClass:"tw-mb-3"},[_c('div',[_vm._v(_vm._s(poule.collaborator.display_name)+" - "+_vm._s(poule.role === 'buyer' ? 'Inkoper' : 'Aanbrenger'))]),(poule.poules.length > 1)?_c('FormulateInput',{attrs:{"type":"select","name":("poule_" + (poule.collaborator.id)),"options":_vm.getPouleOptions(poule.poules),"validation":"required","outer-class":"tw-m-0 tw-w-1/3"}}):(poule.poules.length === 0)?_c('span',{staticClass:"tw-bg-red-500 tw-text-white tw-font-bold tw-mt-1 tw-inline-block tw-px-2 tw-py-1 tw-rounded"},[_vm._v(" Geen poule gevonden ")]):_c('span',{staticClass:"tw-bg-gray-500 tw-text-white tw-font-bold tw-mt-1 tw-inline-block tw-px-2 tw-py-1 tw-rounded"},[_vm._v(" "+_vm._s(poule.poules[0].poule_name)+" ")])],1)}),0)]):_vm._e()]:_vm._e(),_c('div',{staticClass:"tw-flex tw-flex-col tw-items-end tw-w-full"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":!_vm.values.collaborator || isLoading || !_vm.referralSelectionComplete,"outer-class":"tw-mt-4"}},[_c('i',{class:[
              'fas tw-mr-2',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]}),_vm._v(" Toewijzen ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }