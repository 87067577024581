var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.survey.collaborator_comments.length)?_c('details',[_c('summary',{staticClass:"tw-cursor-pointer tw-italic"},[_vm._t("summary",function(){return [_vm._v(" Opmerkingen van de medewerker ("+_vm._s(_vm.survey.collaborator_comments.length)+") ")]})],2),_vm._l((_vm.survey.collaborator_comments),function(ref){
var comment = ref.comment;
var collaborator = ref.collaborator;
var id = ref.id;
return _c('div',{key:id,staticClass:"tw-my-2 tw-flex tw-gap-2 tw-items-start tw-max-w-md"},[_c('div',{staticClass:"tw-flex-grow"},[_c('h4',{staticClass:"tw-m-0"},[_vm._v(" "+_vm._s(collaborator.first_name)+" "+_vm._s(collaborator.last_name)+" ")]),_c('p',{staticClass:"tw-italic tw-my-0"},[_vm._v(" "+_vm._s(comment)+" ")])]),(collaborator.id === _vm.collaboratorId)?_c('button',{class:[
          'tw-mt-4 tw-px-1 tw-py-0.5 tw-text-xs tw-rounded tw-border tw-border-success tw-text-success',
          'hover:tw-bg-success hover:tw-text-white'
        ],attrs:{"type":"button","title":"Opmerking bewerken"},on:{"click":function($event){return _vm.editComment(id, comment)}}},[_c('i',{staticClass:"far fa-pencil"})]):_vm._e()])})],2):_vm._e(),(!_vm.survey.collaborator_comments.find(function (ref) {
                var collaborator = ref.collaborator;

                return collaborator.id === _vm.collaboratorId;
}))?_c('button',{staticClass:"link tw-mt-2",attrs:{"type":"button","title":"Voeg een nieuwe opmerking toe"},on:{"click":function($event){return _vm.addComment(_vm.survey.id)}}},[_c('i',{staticClass:"far fa-comment-plus tw-mr-1"}),_vm._v(" Voeg een nieuwe opmerking toe ")]):_vm._e(),_c('BaseModal',{ref:"commentModal",attrs:{"title":"Opmerking"}},[_c('FormulateForm',{attrs:{"name":"surveyCommentForm"},on:{"submit":_vm.submitComment},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"textarea","name":"comment","label":"Ondernomen acties door Dewaele","placeholder":"Ondernomen acties door Dewaele","outer-class":"tw-my-0"}}),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":"tw-mt-4"}},[_c('i',{class:[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]}),_vm._v(" Opslaan ")])],1)]}}]),model:{value:(_vm.commentForm),callback:function ($$v) {_vm.commentForm=$$v},expression:"commentForm"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }