<template>
  <div>
    <Modal ref="modal" title="Nieuwe activiteit" size="xl">
      <div slot="header">
        <p v-if="entity && entity.visit_arrangement">
          <strong>Bezoekregeling: </strong> {{ entity.visit_arrangement }}
        </p>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <form class="form-horizontal" method="POST" id="frm-event">
            <div class="form-group">
              <ActivityButtonGroup
                ref="activityType"
                @selected-activity-type-id="setSelectedActivityTypeId"
              />
            </div>

            <div class="form-group">
              <label for="id_title" class="control-label">Titel</label>
              <input type="text" id="id_title" placeholder="Titel voor de activiteit"
                     class="form-control" v-model="title">
            </div>
            <div class="form-group">
              <label class="control-label">Omschrijving</label>
              <textarea
                v-model="description"
                rows="5"
                placeholder="Deze omschrijving zal gebruikt worden als je de activiteit inplant"
                class="form-control"
              />
            </div>

            <div class="form-group">
              <label>
                <a @click="selectColleague">Collega's uitnodigen</a>
              </label>
              <br />
              <div id="id_activity_invites" style="margin-left: 20px">
                <CollaboratorPlannerWidget
                  v-for="collaborator in collaborators"
                  v-bind:collaborator="collaborator"
                  v-bind:key="collaborator.id"
                  @deselect="removeCollaborator"
                />
              </div>
            </div>
            <div class="form-group">
              <h3 class="m-t-none m-b" id="lead_time_header">Tijd</h3>
              <DateRangePicker
                ref="dateRange"
                @hook:mounted="setDefaultDate"
                @dateUpdated="dateRangeUpdate"
              />
            </div>
          </form>
          <SMSEmailConfirmation
            :activity-type-id="activityTypeId"
            :add-negative-margin='true'
            :lead="lead"
            @data-updated="setSmsEmailConfirmationData"
          />
          <div class="tw--mx-4 tw-grid tw-grid-cols-3 tw-gap-1">
            <a class="btn btn-primary" @click="planActivity">Inplannen</a>
            <a class="btn btn-success" @click="logActivity">Afpunten</a>
            <a class="btn btn-danger" @click="breakActivity">Breaken</a>
          </div>
        </div>
        <div class="col-sm-8">
          <IAMPlanner ref="planner" :deferRender="true" @dateUpdated="dateRangeUpdate" />
        </div>
      </div>
    </Modal>
    <SelectCollaboratorModal ref="selectCollaboratorModal" @select="addCollaborator" />
    <ActivityFeedbackModal
      ref="feedbackModal"
      :lead="lead"
      :activity="newActivity"
      :show-checkbox="false"
      :show-details-button="false"
      :show-finish-button="showFinishButton"
      :show-break-button="showBreakButton"
      :show-deal-button="false"
      @activity-finished="activityFinished"
    />
    <LeadBreakHmodhFormOpener ref="hmodhOpener" />
  </div>
</template>

<script>
import Vue from 'vue'
import $ from 'jquery'
import moment from 'moment'
import StoreIntegration from '@/store/integration'
import LeadBreakHmodhFormOpener from '@/components/sales/LeadBreakHmodhFormOpener'

import Modal from '@/components/iam/Modal'
import IAMPlanner from '@/components/planner/iam-planner'
import DateRangePicker from '@/components/iam/DateRangePicker'
import ActivityButtonGroup from '@/components/sales/ActivityButtonGroup'
import ActivityFeedbackModal from '@/components/sales/ActivityFeedbackModal'
import SelectCollaboratorModal from '@/components/organization/SelectCollaboratorModal'
import SMSEmailConfirmation from '@/components/sales/SMSEmailConfirmation'
import { errorModal, startLoadingModal, stopLoadingModal, successModal } from '@/modalMessages'
import { createActivity } from '@/services/sales'

Vue.component('CollaboratorPlannerWidget', {
  props: ['collaborator', 'color'],
  template: '<div style="display: block">' +
      '<input type="checkbox" class="chk-selected-planner" :value="collaborator.id" checked @click="deselectCollaborator(collaborator)" /> {{ collaborator.first_name }} {{ collaborator.last_name }}</div>',
  methods: {
    deselectCollaborator (collaborator) {
      this.$emit('deselect', collaborator)
    }
  }
})

export default {
  name: 'NewActivityModal',
  mixins: [StoreIntegration],
  props: ['lead', 'mainCollaborator'],
  components: {
    SelectCollaboratorModal,
    Modal,
    IAMPlanner,
    ActivityButtonGroup,
    ActivityFeedbackModal,
    DateRangePicker,
    SMSEmailConfirmation,
    LeadBreakHmodhFormOpener
  },
  data () {
    return {
      title: '',
      description: '',
      collaborators: [],
      mainCollab: this.mainCollaborator,
      newActivity: null,
      showBreakButton: false,
      showFinishButton: true,
      activityTypeId: null,
      smsEmailConfirmationData: null
    }
  },
  methods: {
    setDefaultDate () {
      this.$refs.dateRange.setStartDate(moment())
      this.$refs.dateRange.setEndDate(moment().add(30, 'minutes'))
    },
    dateRangeUpdate (event) {
      const source = event.source
      const startDate = event.startDate
      const endDate = event.endDate
      const dateRangePicker = this.$refs.dateRange
      const planner = this.$refs.planner
      if (source === 'dateRangePicker') {
        // update the planner
        planner.setEvent(startDate, endDate)
      } else if (source === 'planner') {
        // update the date range picker
        dateRangePicker.setStartDate(startDate)
        dateRangePicker.setEndDate(endDate)
      } else {
        console.error('Invalid source specified')
      }
    },
    show () {
      this.$refs.modal.show()

      setTimeout(() => {
        const entity = this.lead.entity
        if (typeof entity === 'number') {
          if (this.lead.entity_type === 'property') {
            this.$store.dispatch('properties/loadProperty', entity).then((property) => {
              this.setTitle(property)
            })
          }
          if (this.lead.entity_type === 'project') {
            this.$store.dispatch('properties/loadProject', entity).then((project) => {
              this.setTitle(project)
            })
          }
        }
        this.$refs.planner.render()
      }, 300)
    },
    hide () {
      this.$refs.modal.hide()
    },
    url () {
      return '/sales/funnel/' + this.lead.funnel.id + '/' + this.lead.id
    },
    setTitle (entity) {
      if (!this.title && entity !== null && typeof entity === 'object') {
        this.title = entity.street + ' ' + entity.number + ' ' + (entity.box || '') + ' ' + entity.city.name
      }
    },
    async createActivity (finished = false) {
      const activityType = this.$refs.activityType.getSelectedActivity()
      let name = ''

      if (this.title) name = `${activityType.name} | ${this.title}`
      else name = `${activityType.name}`

      const datesEntered = this.$refs.dateRange.datesEntered(true, true)
      if (!datesEntered) {
        const errorMessage = 'Gelieve een startdatum en een einddatum in te voeren.'
        return Promise.reject(new Error(errorMessage))
      }

      const startDatetime = this.$refs.dateRange.getStartDateAsDatetime()
      const endDatetime = this.$refs.dateRange.getEndDateAsDatetime()
      if (startDatetime > endDatetime) {
        return Promise.reject(new Error('Einddatum moet na startdatum liggen'))
      }

      const data = {
        type: activityType.id,
        name: name,
        description: this.description,
        finished: finished,
        status: finished ? 4 : 1, // 4: finished, 1: open
        start_date: this.$refs.dateRange.getStartDate(true),
        end_date: this.$refs.dateRange.getEndDate(true)
      }

      if (!finished) {
        // Build list of attendees to invite
        const invites = []
        $.each(this.collaborators, function (index, collaborator) {
          invites.push(collaborator.id)
        })
        data.invites = invites
      }

      if (this.smsEmailConfirmationData?.confirmationApplies) {
        data.send_email_sms_reminder = this.smsEmailConfirmationData.sendEmailSmsReminder
        data.send_email_confirmation = this.smsEmailConfirmationData.sendEmailConfirmation
        data.confirmation_location = this.smsEmailConfirmationData.confirmationLocation
        data.confirmation_address = this.smsEmailConfirmationData.confirmationAddress
      }

      const response = await createActivity(this.lead.id, data)
      return response.data
    },

    showActivityError (error) {
      let errorMessage = error.message
      if (!errorMessage) {
        errorMessage = 'Er ging iets mis bij het inplannen van deze activiteit'
      }
      errorModal(errorMessage)
    },

    async planActivity () {
      try {
        startLoadingModal('Bezig met het inplannen van deze activiteit...')
        const activity = await this.createActivity(false)
        this.clear()
        this.$emit('activity-saved')
        let swalMessage = null
        if (this.mainCollab) {
          swalMessage = `Deze activiteit is ingepland in de agenda van ${this.mainCollab.display_name}.`
        } else {
          swalMessage = 'Deze activiteit is ingepland in je agenda.'
        }
        successModal(swalMessage)
        this.hide()
        return activity
      } catch (error) {
        this.showActivityError(error)
      }
    },
    async logActivity () {
      try {
        startLoadingModal('Bezig met het loggen van deze activiteit...')
        const activity = await this.createActivity(true)
        this.clear()
        this.newActivity = activity
        this.$refs.planner.showFinishButton = true
        this.$refs.feedbackModal.show()
        this.$refs.planner.refresh()
        this.$emit('activity-saved')
        stopLoadingModal()
        return activity
      } catch (error) {
        this.showActivityError(error)
      }
    },
    async breakActivity () {
      try {
        startLoadingModal('Bezig met het loggen van deze activiteit...')
        const activity = await this.createActivity(true)
        this.clear()
        this.newActivity = activity
        this.showFinishButton = false
        this.showBreakButton = true
        this.$refs.feedbackModal.show()
        this.$refs.planner.refresh()
        this.$emit('activity-saved')
        stopLoadingModal()
      } catch (error) {
        this.showActivityError(error)
      }
    },
    activityFinished (activity, lead) {
      this.$refs.feedbackModal.hide()
      this.$emit('activity-saved')
      this.clear()
      this.$refs.hmodhOpener.trigger(lead.id)
    },
    selectColleague () {
      this.$refs.selectCollaboratorModal.show()
    },
    addCollaborator (collaborator) {
      this.collaborators.push(collaborator)
      this.$refs.planner.addCollaborator(collaborator)
    },
    removeCollaborator (collaborator) {
      const idx = this.collaborators.indexOf(collaborator)
      this.collaborators.splice(idx, 1)
      this.$refs.planner.removeCollaborator(collaborator)
    },
    renderPlanner () {
      this.$refs.planner.render()
    },
    clear () {
      this.title = ''
      this.description = ''
      this.newActivity = null
    },
    setSelectedActivityTypeId (activityTypeId) {
      this.activityTypeId = activityTypeId
    },
    setSmsEmailConfirmationData (values) {
      this.smsEmailConfirmationData = values
    }
  },
  watch: {
    collaborators (value) {
      $.each(value, function (i, collaborator) {
        this.$refs.planner.addCollaborator(collaborator)
      })
    },
    mainCollaborator () {
      this.mainCollab = this.mainCollaborator
    }
  },
  computed: {
    collaborator () {
      return this.$store.state.collaborator
    },
    entity () {
      if (this.lead) {
        return this.getFromStore('properties/getPropertyById', this.lead.entity, 'properties/loadProperty', this.lead.entity)
      }
      return null
    }
  }
}
</script>
