<template>
  <BaseModal ref="modal" title="Activiteit annuleren">
    <FormulateForm
      v-model="values"
      @submit="submit"
    >
      <FormulateInput
        type="select"
        name="cancellation_reason"
        label="Reden voor annulatie"
        placeholder="Selecteer reden"
        validation="bail|required"
        :options="reasons"
      />
      <FormulateInput
        v-if="values.cancellation_reason != 1"
        type="checkbox"
        name="send_cancellation_email"
        label="Annuleringsmail versturen"
      />
      <FormulateInput
        type="submit"
        :disabled="isLoading"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Activiteit annuleren
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { errorModal } from '@/modalMessages'
import { cancelActivity, getCancellationReasons } from '@/services/sales'

export default {
  name: 'ActivityCancelModal',
  props: {
    activity: {
      type: Object
    }
  },
  data () {
    return {
      reasons: [],
      isLoading: false,
      values: {
        cancellation_reason: '',
        send_cancellation_email: false
      }
    }
  },

  methods: {
    async show () {
      const response = await getCancellationReasons()
      this.reasons = response.data?.results
      this.$refs.modal.show()
    },
    async submit (data) {
      try {
        const response = await cancelActivity(this.activity.id, data)
        this.$emit('activity-cancelled')
        this.$refs.modal.hide()
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het annuleren van deze activiteit.')
      }
    }
  }
}
</script>
