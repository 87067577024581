<!--
ActivityTimeLineItem
  This component represents an Activity on the timeline of a lead

Author: Michael Anckaert

Functionality
  This component displays the activity, allows the user to click a finish or edit button and allows for deletion of
  the activity. Deletion of the activity is handled by this component, the other actions are events that are emitted
  and should be handled by other components.

Public API
  Events emitted by this component
    finish-activity   Triggered when the 'Finish activity' button is clicked, passes the activity to the handler

    edit-activity     Triggered when the 'Edit activity' button is clicked, passes the activity to the handler
-->
<template>
  <div>
    <div class="vertical-timeline-block tw-mb-4" v-if="activity.type">
      <div :class="timelineClass()" class="vertical-timeline-icon">
        <i :class="activity.type.desktop_icon|icon" class="fa"></i>
      </div>
      <div class="vertical-timeline-content">
        <div class="row">
          <div class="col-xs-10">
            <div class="tw-flex tw-gap-x-2 tw-items-center">
              <h3 class="tw-font-semibold tw-mt-3 tw-mb-4">{{ activity.type.name }}</h3>
              <ActivityTimelineItemLabel :activity="activity" />
            </div>
          </div>

          <div class="col-xs-2 item-buttons tw-mt-1">
            <div class="activity-dropdown" v-if="activity.type.id !== 10">
              <button class="btn btn-link dropdown-toggle"
                      data-toggle="dropdown">
                <i class="fa fa-bars"></i>
              </button>
              <ul class="dropdown-menu">
                <li v-if="!isFinished(activity) && allowEmailMessage(activity.type)">
                  <a @click="sendEmailConfirmation">Stuur e-mailbevestiging</a>
                </li>
                <li v-if="allowSmsMessage(activity)">
                  <a @click="sendSMSConfirmation">{{ smsDescription }}</a>
                </li>
                <li v-if="isFinished(activity)">
                  <a @click="rapport">Stuur feedback naar eigenaar</a>
                </li>
                <li>
                  <a  v-if="!activity.type.finished_by_default" @click="editActivity"
                  >
                    Bewerken
                  </a>
                </li>
                <li v-if="!isFinished(activity) && !isFutureIntakeOrVisitActivity(activity)">
                  <a @click="cancelActivity"
                  >
                    Annuleren
                  </a>
                </li>
                <li><a @click="deleteActivity">Verwijderen</a></li>
              </ul>
            </div>

            <button @click="toggleDetails" class="btn btn-link btn-toggle-details">
              <i :class="{'fa-minus-square-o': showDetails, 'fa-plus-square-o': !showDetails}" class="fa"></i>
            </button>
          </div>
        </div>
        <div v-show="showDetails">
          <span class="tw-inline-block tw-mb-4 tw-bg-gray-200 tw-py-1 tw-px-2 tw-rounded-sm tw-shadow-sm">
            <i class="fa fa-calendar tw-mr-1" />{{ activity.start_date|date-day }} {{ activity.start_date|time }}<span v-if="activity.end_date"> - {{ activity.end_date|time }}</span>
          </span>
          <!-- Show activity description & feedback -->
          <span v-if="isFinished(activity)">
            <p v-html="activity.description" v-if="activity.type.finished_by_default"></p>
            <p v-if="activity.feedback">
              <strong>Intern: </strong>
              <span v-html="activity.feedback"></span>
            </p>
            <p v-if="activity.external_feedback" class="tw-mb-4">
              <strong>Rapportering: </strong>
              <span v-html="activity.external_feedback"></span>
            </p>
          </span>
          <span v-else>
            <p v-if="activity.description">{{ activity.description }}</p>
          </span>
          <ActivityTimelineItemCommunication :activity="activity" />
          <div class="tw-flex tw-flex-wrap-reverse tw-justify-between tw-gap-y-2">
            <div>
              <div>
                <small>
                  Aangemaakt door {{ collaborator ? collaborator.display_name : 'IAM' }} op {{ activity.created_on|datetime }}
                </small>
                <br>
                <small v-if="activity.finished_on">
                  Afgewerkt door {{ collaborator ? collaborator.display_name : 'IAM' }} op {{ activity.finished_on|datetime }}
                </small>
              </div>
            </div>
            <div class="item-actions">
              <button
                v-if="isFutureIntakeOrVisitActivity(activity)"
                type="button"
                class="btn btn-sm btn-warning finish-button"
                @click="cancelActivity"
              >
                Annuleren
              </button>
              <a
                v-else-if="!isFinished(activity)"
                @click="finish"
                class="btn btn-sm btn-warning finish-button"
              >
                Afpunten
              </a>
            </div>
          </div>
        </div>
        <template v-for="survey in activity.surveys">
          <button
            v-if="survey.completed_on"
            :key="survey.id"
            :disabled="surveyLoading[survey.id]"
            type="button"
            class="tw-mt-2 tw-relative"
            @click="openSurveyModal(survey)"
          >
            <div :class="['tw-flex tw-gap-2', { 'tw-opacity-30': surveyLoading[survey.id] }]">
              <img
                :alt="survey.score"
                :src="scores[survey.score].icon"
                class="tw-h-6"
              />
              <div class="tw-text-xs link tw-text-left">
                <h5 class="tw-m-0">
                  {{ survey.type.name }}
                </h5>
                <span class="tw-italic">
                  {{ survey.comment || scores[survey.score].label }}
                </span>
              </div>
            </div>

            <div v-if="surveyLoading[survey.id]" class="tw-absolute tw-top-1/4 tw-left-1/2">
              <i
                class="fal fa-spinner-third fa-spin tw-text-xl tw-leading-none"
              />
            </div>
          </button>
        </template>
      </div>
    </div>
    <ActivityCancelModal
      ref="activityCancelModal"
      :activity="activity"
      @activity-cancelled="activityCancelled"
    />
    <BaseModal ref="surveyModal" title="CSAT-bevraging">
      <div v-if="surveyDetails" class="tw-flex tw-gap-5">
        <img
          alt="score"
          :src="scores[surveyDetails.score].icon"
          class="tw-h-9"
        />
        <div>
          <div class="tw-mb-4">
            <h3 class="tw-my-0">
              {{ surveyDetails.type.name }}
            </h3>
            <span class="tw-italic">
              Score: {{ surveyDetails.score }} ({{ scores[surveyDetails.score].label }})
            </span>
          </div>
          <router-link
            :to="{ name: 'ContactDetails', params: { id: surveyDetails.contact.id } }"
            target="_blank"
          >
            <h4 class="tw-m-0">
              {{ surveyDetails.contact.display_name }}
            </h4>
          </router-link>
          <p class="tw-italic">
            {{ surveyDetails.comment }}
          </p>

          <SurveysAddEditComment
            :survey="surveyDetails"
            @submitted="openSurveyModal(surveyDetails, true)"
          />
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import moment from 'moment'
import scores from '@/utils/surveyScores'
import StoreIntegration from '@/store/integration'
import { isFutureIntakeOrVisitActivity } from '@/utils/sales'
import { startLoadingModal, questionModal, successModal, errorModal } from '@/modalMessages'

import EventBus from '@/components/iam/bus'
import ActivityTimelineItemCommunication from '@/components/sales/ActivityTimelineItemCommunication'
import ActivityTimelineItemLabel from '@/components/sales/ActivityTimelineItemLabel'
import ActivityCancelModal from '@/components/sales/ActivityCancelModal'
import SurveysAddEditComment from '@/components/SurveysAddEditComment.vue'

import { deleteSalesActivity } from '@/services/sales'
import { getSurveyDetails } from '@/services/surveys'

export default {
  name: 'ActivityTimelineItem',
  components: {
    ActivityTimelineItemCommunication,
    ActivityTimelineItemLabel,
    ActivityCancelModal,
    SurveysAddEditComment
  },
  mixins: [StoreIntegration],
  props: ['activity'],
  constants: {
    scores,
    VISIT_ACTIVITY_TYPE_ID: 2,
    INTAKE_MEETING_ACTIVITY_TYPE_ID: 14,
    OUTGOING_PHONE_CALL_ACTIVITY_TYPE_ID: 126
  },
  data () {
    return {
      showDetails: true,
      surveyDetails: null,
      surveyLoading: {}
    }
  },
  computed: {
    collaborator () {
      return this.getFromStore('organization/getCollaboratorById', this.activity.collaborator, 'organization/loadCollaboratorById', this.activity.collaborator)
    },
    smsDescription () {
      if (this.activity?.type?.id === this.OUTGOING_PHONE_CALL_ACTIVITY_TYPE_ID) {
        return 'Stuur uitgaande sms'
      }
      return 'Stuur sms-bevestiging'
    }
  },
  filters: {
    date (value) {
      if (!value) return ''
      return moment(value).format('DD-MM-YYYY HH:mm:ss')
    },
    icon (value) {
      return 'fa-' + value
    }
  },
  methods: {
    isFutureIntakeOrVisitActivity,

    sendSMSConfirmation (event) {
      this.$emit('send-confirmation', { type: 'SMS', activity: this.activity })
    },
    sendEmailConfirmation (event) {
      this.$emit('send-confirmation', { type: 'Email', activity: this.activity })
    },
    finish () {
      this.$emit('finish-activity', this.activity)
    },
    cancelActivity () {
      return this.$refs.activityCancelModal.show()
    },
    timelineClass () {
      if (this.activity.status === 4) {
        return 'navy-bg'
      } else if (this.activity.status === 2 || this.activity.status === 3) {
        return 'tw-bg-gray-e9'
      } else {
        return 'yellow-bg'
      }
    },
    isFinished (activity) {
      return activity.status !== 1
    },
    editActivity () {
      this.$emit('editActivity', this.activity.id)
    },
    rapport () {
      EventBus.$emit('sales-send-rapport', this.activity.match, this.activity.id)
    },
    toggleDetails (event) {
      this.setShowDetails(!this.showDetails)
    },
    setShowDetails (showDetails) {
      this.showDetails = showDetails
    },
    allowEmailMessage (type) {
      return [this.VISIT_ACTIVITY_TYPE_ID, this.INTAKE_MEETING_ACTIVITY_TYPE_ID].includes(type.id)
    },
    allowSmsMessage (activity) {
      if (this.OUTGOING_PHONE_CALL_ACTIVITY_TYPE_ID === activity.type.id) return true
      // Sms message for intake/visit activities should be allowed if unfinished
      return [this.VISIT_ACTIVITY_TYPE_ID, this.INTAKE_MEETING_ACTIVITY_TYPE_ID].includes(activity.type.id) && !this.isFinished(activity)
    },
    activityCancelled () {
      this.$emit('reload-activities')
    },

    async deleteActivity () {
      try {
        const result = await questionModal('Weet je zeker dat je deze activiteit wil verwijderen?')
        if (!result.value) return

        startLoadingModal('Bezig met het verwijderen van deze activiteit...')
        // Call the activity finish API call
        const response = await deleteSalesActivity(this.activity.id)
        this.$emit('activity-removed')
        successModal('Activiteit verwijderd', true)
        return response
      } catch (error) {
        errorModal('Helaas ging er iets mis bij het verwijderen van deze activiteit. Gelieve nogmaals te proberen, als het probleem zich blijft voordoen wil je dit dan melden via de helpdesk?')
      }
    },
    async openSurveyModal (survey, alreadyOpen = false) {
      this.$set(this.surveyLoading, survey.id, true)
      const response = await getSurveyDetails(survey.id)
      this.surveyDetails = response.data
      if (!alreadyOpen) this.$refs.surveyModal.show()
      this.$set(this.surveyLoading, survey.id, false)
      return response
    }
  }
}
</script>

<style scoped>
  .item-buttons, .item-footer {
    display: flex;
  }

  .item-metadata {
    flex-grow: 2;
  }
</style>
