<template>
  <BaseModal ref="modal" title="Aanvaarding bod" max-width="tw-max-w-xl" @hide="hide">
    <div class="tw-flex tw-justify-between tw-gap-4 tw-flex-wrap-reverse">
      <div>
        <p v-if="bid.created_by">
          Aangemaakt door
          <b>{{ bid.created_by.first_name }}</b>
          op
          <b>{{ bid.created_on | date-day }}</b>
        </p>

        <p v-if="bid.updated_by">
          Gewijzigd door
          <b>{{ bid.updated_by.first_name }}</b>
          op
          <b>{{ bid.updated_on | date-day }}</b>
        </p>
      </div>

      <FormulateInput
        v-model="editMode"
        type="toggle"
        debounce
        name="edit_mode"
        label="Bewerking"
        outer-class="tw-my-0"
      />
    </div>

    <span class="formulate-label">Kandidaat-kopers</span>
    <ul v-if="bid.property_bid_contacts.length">
      <li
        v-for="object in bid.property_bid_contacts"
        :key="object.id"
      >
        <router-link
          v-if="object.contact"
          :to="{ name: 'ContactDetails', params: { id: object.contact.id } }"
          target="_blank"
        >
          {{ object.contact.display_name }}
        </router-link>
      </li>
    </ul>

    <div v-if="!editMode" class="formulate-label tw-my-4">
      Bod: {{ currency(bid.amount) }}
    </div>

    <FormulateForm
      #default="{ isLoading }"
      v-model="bid"
      name="confirmEditBidForm"
      @submit="submit"
    >
      <FormulateInput
        type="date"
        name="accepted_date"
        label="Datum aanvaarding"
        placeholder="YYYY-MM-DD"
        :value="today.date"
        :validation="editMode ? '' : 'bail|required|date:YYYY-MM-DD'"
        data-lpignore="true"
        outer-class="tw-my-4 tw-grid md:tw-grid-cols-2"
      />
      <FormulateInput
        type="file"
        name="owner_acceptance_file"
        label="Bijlage uploaden (aanvaarding eigenaar)"
        :uploader="uploadFile"
        :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
        :file-class="['tw-h-10 !tw-mt-1']"
        element-class="formulate-input-element formulate-input-element--file"
        wrapper-class="formulate-input-wrapper"
        outer-class="formulate-input"
      >
        <template #file="context">
          <BaseFileUploadDisplay
            v-bind="context"
            :show-remove-file="true"
          />
        </template>
      </FormulateInput>

      <transition name="fade" mode="out-in">
        <div v-if="editMode">
          <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
            <FormulateInput
              type="number"
              name="amount"
              label="Bod"
              placeholder="Bod"
              lang="nl"
              step="0.01"
              validation="bail|required|number"
              keep-model-data="true"
              data-lpignore="true"
              outer-class="tw-my-0"
            />

            <FormulateInput
              type="date"
              name="bid_date"
              label="Biedingsdatum"
              placeholder="YYYY-MM-DD"
              validation="bail|required|date:YYYY-MM-DD"
              keep-model-data="true"
              data-lpignore="true"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="date"
              name="valid_until_date"
              label="Geldig tot"
              placeholder="YYYY-MM-DD"
              :min="bid.bid_date"
              :validation="[
                ['bail'],
                ['optional'],
                ['date', 'YYYY-MM-DD'],
                ['after', bid.bid_date]
              ]"
              keep-model-data="true"
              data-lpignore="true"
              outer-class="tw-my-0"
            />
          </div>

          <FormulateInput
            type="textarea"
            name="comment"
            label="Opmerking"
            placeholder="Opmerking"
            keep-model-data="true"
            :input-class="['tw-h-24']"
          />

          <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
            <FormulateInput
              type="select"
              name="suspensive_condition"
              label="Opschortende voorwaarde"
              placeholder="Selecteer een voorwaarde"
              :options="suspensiveConditions"
              keep-model-data="true"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="file"
              name="contact_confirmation_file"
              label="Bijlage uploaden (bieder)"
              keep-model-data="true"
              :uploader="uploadFile"
              :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
              :file-class="['tw-h-10 !tw-mt-1']"
              element-class="formulate-input-element formulate-input-element--file"
              wrapper-class="formulate-input-wrapper"
              outer-class="formulate-input !tw-my-0"
            >
              <template #file="context">
                <BaseFileUploadDisplay
                  v-bind="context"
                  :show-remove-file="true"
                />
              </template>
            </FormulateInput>
          </div>

          <FormulateInput
            type="checkbox"
            name="is_confirmed_in_writing"
            label="Dit bod is schriftelijk ontvangen"
            keep-model-data="true"
          />
        </div>
      </transition>

      <FormulateErrors class="tw-text-right" />

      <div class="tw-flex tw-justify-end">
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :input-class="[editMode ? 'tw-bg-gray-500' : '']"
        >
          <i
            :class="[
              'fas tw-mr-2',
              isLoading
                ? 'fa-spinner-third fa-spin'
                : (editMode ? 'fa-save' : 'fa-badge-check')
            ]"
          />
          {{ editMode ? 'Opslaan' : 'Bod aanvaard door eigenaar' }}
        </FormulateInput>
      </div>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { mapActions } from 'vuex'
import { successModal } from '@/modalMessages'
import { parseFileObj, today, currency } from '@/utils/helpers'

import EventBus from '@/components/iam/bus'

import { getSuspensiveConditions } from '@/services/apiService'
import {
  uploadFile,
  createPropertyFile,
  updatePropertyBid,
  acceptPropertyBid
} from '@/services/properties'

export default {
  name: 'PropertyBidConfirmEditModal',
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    }
  },
  constants: {
    today
  },
  data () {
    return {
      editMode: false,
      bid: {
        property_bid_contacts: []
      },
      suspensiveConditions: []
    }
  },
  methods: {
    currency,
    ...mapActions('properties', ['loadProperty']),

    hide () {
      this.$emit('hideWithoutAcceptance')
    },

    async show (bid) {
      const { contact_confirmation_file, suspensive_condition, ...values } = bid
      this.bid = {
        contact_confirmation_file: [parseFileObj(contact_confirmation_file)],
        suspensive_condition: suspensive_condition?.id,
        ...values
      }
      const response = await this.fetchSuspensiveConditions()
      this.$refs.modal.show()
      return response
    },
    async fetchSuspensiveConditions () {
      const response = await getSuspensiveConditions()
      const conditions = response.data?.results || []
      this.suspensiveConditions = conditions.map(({ id, name }) => {
        return { label: name, value: id }
      })
      return response
    },

    async uploadFile (file, progress) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(33)
        const { key, filename } = (await uploadFile(this.propertyId, formData)).data
        progress(66)
        const payload = { key, filename }
        const response = await createPropertyFile(this.propertyId, payload)
        progress(99)
        const parsedFile = parseFileObj(response.data)
        progress(100)

        return [parsedFile]
      } catch (error) {
        this.$formulate.handle(error, 'confirmEditBidForm')
      }
    },

    async updateBid (data) {
      const { id, accepted_date, contact_confirmation_file, owner_acceptance_file, ...payload } = data
      if (contact_confirmation_file?.length) {
        payload.contact_confirmation_file = contact_confirmation_file[0]?.[0]?.id
      }
      if (owner_acceptance_file?.length) {
        payload.owner_acceptance_file = owner_acceptance_file[0]?.[0]?.id
      }

      const response = await updatePropertyBid(this.propertyId, id, payload)
      this.$emit('bidUpdated')
      this.editMode = false
      return response
    },
    async acceptBid (data) {
      const { id, accepted_date, owner_acceptance_file } = data
      const payload = { accepted_date }
      if (owner_acceptance_file?.length) {
        payload.owner_acceptance_file = owner_acceptance_file[0]?.[0]?.id
      }

      const response = await acceptPropertyBid(this.propertyId, id, payload)
      EventBus.$emit('bid-accepted')
      this.$refs.modal.hide()
      successModal('Bod succesvol geaccepteerd')
      this.$emit('bidAccepted')
      return response
    },
    async submit (data) {
      try {
        const response = this.editMode
          ? await this.updateBid(data)
          : await this.acceptBid(data)
        return response
      } catch (error) {
        this.$formulate.handle(error, 'confirmEditBidForm')
      }
    }
  }
}
</script>
