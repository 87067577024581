const scores = {
  1: {
    label: 'Helemaal niet',
    icon:
      'https://iam-media-prod.s3.eu-central-1.amazonaws.com/nps-surveys/1.png'
  },
  2: {
    label: 'Onvoldoende',
    icon:
      'https://iam-media-prod.s3.eu-central-1.amazonaws.com/nps-surveys/2.png'
  },
  3: {
    label: 'Neutraal',
    icon:
      'https://iam-media-prod.s3.eu-central-1.amazonaws.com/nps-surveys/3.png'
  },
  4: {
    label: 'Het was oké',
    icon:
      'https://iam-media-prod.s3.eu-central-1.amazonaws.com/nps-surveys/4.png'
  },
  5: {
    label: 'Zeer tevreden',
    icon:
      'https://iam-media-prod.s3.eu-central-1.amazonaws.com/nps-surveys/5.png'
  }
}

export default scores
