<template>
  <span>
    <Modal ref="gdprModal" title="Wat is de GDPR status van een contact?" size="xl">
      <div>
        <div class="row">
          <div class="col-sm-6">
            <strong>
              <span class="label label-primary">GDPR</span> Dit contact heeft toestemming gegeven om hen te contacteren
              (via touchpoints of via e-mail die werd verstuurd vanuit het kantoor / in bulk).
            </strong>
            <p>
              <strong>Wat kan wel nog?</strong>
            </p>
            <ol>
              <li>Mailings, nieuwsbrieven, ...</li>
              <li>HMODH</li>
              <li>Individueel contact over hun dossier / wensen / ...</li>
              <li>Individueel pandenaanbod doorsturen</li>
              <li>Nieuw pand voorstellen aan bestaande contact</li>
            </ol>
            <strong>
              <span class="label label-warning">GDPR</span> We hebben nog geen toestemming gevraagd aan dit contact of we
              hem / haar mogen contacteren.
            </strong>
            <p>
              <strong>Wat kan wel nog?</strong>
            </p>
            <ol>
              <li>HMODH</li>
              <li>Individueel contact over hun dossier / wensen / ...</li>
            </ol>
            <p><strong>Wat kan niet meer?</strong></p>
            <ol>
              <li>Mailings, nieuwsbrieven, ...</li>
              <li>Individueel pandenaanbod doorsturen</li>
              <li>Nieuw pand voorstellen aan bestaande contact</li>
            </ol>
            <div class="alert alert-info" v-if="status === 'orange'">
              <p>Dit contact heeft zijn GDPR toestemming nog niet bevestigd of geweigerd. Druk op de knop
                <strong>Toestemming mail versturen</strong> hieronder om een mail met de GDPR vermelding te versturen.</p>
            </div>
          </div>
          <div class="col-sm-6">
            <strong>
              <span class="label label-danger">GDPR</span> Dit contact heeft aangegeven dat hij / zij geen informatie meer
              van ons wenst te ontvangen.
            </strong>
            <p>
              <strong>Wat kan wel nog?</strong>
            </p>
            <ol>
              <li>Contacteren in een dossier waar ze contractspartij zijn</li>
              <li>Een individuele mail die geen pandenaanbod inhoudt</li>
              <li>Contacteren als hun huis te koop staat ( = persoonlijk contact over hun eigen eigendom)</li>
            </ol>
            <p><strong>Wat kan niet meer?</strong></p>
            <ol>
              <li>Mailings, nieuwsbrieven, ...</li>
              <li>HMODH mails</li>
              <li>Individueel pandenaanbod doorsturen</li>
            </ol>
            <strong>
              <span class="label label-inverse">GDPR</span> Dit contact heeft aangegeven dat zijn / haar data moet verwijderd worden
            </strong>
            <p>
              <strong>Wat kan wel nog?</strong>
            </p>
            <ol>
              <li>Contacteren in een dossier waar ze contractspartij zijn</li>
              <li>Als ze ons eerst zelf opnieuw contacteren</li>
            </ol>
            <p><strong>Wat kan niet meer?</strong></p>
            <ol>
              <li>Mailings, nieuwsbrieven, ...</li>
              <li>HMODH mails</li>
              <li>Individueel pandenaanbod doorsturen</li>
            </ol>
          </div>
        </div>
      </div>
      <div slot="footer">
        <button v-if="status === 'orange'" type="button" class="btn btn-success" @click="openMailClient">
          Toestemmingsmail versturen
        </button>
        <button type="button" data-dismiss="modal" class="btn btn-danger" @click="hide">
          Sluiten
        </button>
      </div>
    </Modal>
    <a :title="label.title" :class="[label.class, 'btn btn-xs']" @click="showGDPRModal">
      {{ text }}
    </a>
  </span>
</template>

<script>
import moment from 'moment'

import EventBus from '@/components/iam/bus'
import Modal from '@/components/iam/Modal'

import { errorModal } from '@/modalMessages'
import { updateContact, createContactCommunication } from '@/services/contacts'
import { mapActions } from 'vuex'

export default {
  name: 'gdprComponent',
  props: ['contact', 'text'],
  components: {
    Modal
  },
  computed: {
    label () {
      const labels = {
        green: { title: 'Toestemming gegeven', class: 'btn-primary' },
        orange: { title: 'Toestemming nog niet gevraagd', class: 'btn-warning' },
        red: { title: 'Toestemming geweigerd', class: 'btn-danger' }
      }
      return labels[this.status]
    },
    mailSubject () {
      const translatedSubjects = {
        nl: 'Je bent belangrijk voor ons',
        fr: `Vous êtes ${this.frenchImportantAdjectiveByGender} pour nous`,
        en: 'You are important for us'
      }
      return translatedSubjects[this.contact.language]
    },
    frenchImportantAdjectiveByGender () {
      const adjectives = {
        M: 'important',
        F: 'importante',
        U: 'important.e'
      }
      return adjectives[this.contact.gender]
    },
    status () {
      let value = ''
      if (this.contact.global_unsubscribe) {
        value = 'red'
      } else if (this.contact.consent_asked) {
        value = 'green'
      } else {
        value = 'orange'
      }
      return value
    }
  },
  methods: {
    ...mapActions('contacts', ['loadContactById']),

    showGDPRModal () {
      this.$refs.gdprModal.show()
    },
    hide () {
      this.$refs.gdprModal.hide()
    },
    openMailClient () {
      this.hide()
      EventBus.$emit('mailclient-prefill-and-show', {
        contacts: [{ type: 'candidate', contact: this.contact }],
        subject: this.mailSubject,
        template: {
          id: 120,
          language: this.contact.language.toLowerCase(),
          variables: {
            firstname: this.contact.first_name,
            last_name: this.contact.last_name,
            company_name: this.contact.company_name,
            profile_link: `https://my.dewaele.com/newsletters?uuid=${this.contact.uuid}`
          }
        },
        callback: async (context) => {
          try {
            const communicationData = {
              external: context.email_body.replace(/<[^>]*>?/gm, ''),
              timestamp: moment().format('YYYY-MM-DDThh:mm'),
              type: 4 // email sent
            }
            await createContactCommunication(this.contact.id, communicationData)

            const { id, groups } = this.contact
            const response = await updateContact({ id, groups, consent_asked: true })

            await this.loadContactById(this.contact.id)
            return response
          } catch (e) {
            const errorMessage = 'De GDPR mailing is verzonden, maar er ging iets mis bij het loggen van de communicatie.'
            errorModal(errorMessage)
            console.error(e)
          }
        }
      })
    }
  }
}
</script>
