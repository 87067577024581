<template>
  <span
    v-if="message"
    class="tw-inline-block tw-px-1 tw-mb-1 tw-rounded tw-bg-gray-e9"
  >
    {{ message }}
  </span>
</template>

<script>
export default {
  name: 'ActivityTimelineLabel',
  props: {
    activity: {
      type: Object
    }
  },
  constants: {
    STATUS_ACTIVITY_CANCELLED: 2,
    STATUS_ACTIVITY_ABORTED: 3,
    VISIT_ACTIVITY_TYPE_ID: 2,
    INTAKE_MEETING_ACTIVITY_TYPE_ID: 14,
    OUTGOING_PHONE_CALL_ACTIVITY_TYPE_ID: 126
  },
  computed: {
    message () {
      if (this.activity?.type?.id === this.OUTGOING_PHONE_CALL_ACTIVITY_TYPE_ID && this.activity?.status === this.STATUS_ACTIVITY_ABORTED) return 'Voicemail'
      if (![this.INTAKE_MEETING_ACTIVITY_TYPE_ID, this.VISIT_ACTIVITY_TYPE_ID].includes(this.activity?.type?.id)) return ''
      if (this.activity?.status === this.STATUS_ACTIVITY_CANCELLED) return 'Annulatie'
      if (this.activity?.status === this.STATUS_ACTIVITY_ABORTED) return 'No-show'
      return ''
    }
  }
}
</script>
