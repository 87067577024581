export default {
  methods: {
    getFromStore (getter, getterPayload, action, actionPayload) {
      if (this.startLoading) {
        this.startLoading()
      }

      const s = this.$store.getters[getter](getterPayload)
      if (!s) {
        this.$store.dispatch(action, actionPayload).then(() => {
          if (this.finishLoading) {
            this.finishLoading()
          }
        })
      } else {
        if (this.finishLoading) {
          this.finishLoading()
        }
      }
      return s
    }
  }
}
