<template>
    <span class="tw-break-all">
      <a @click="openMailClient"> {{ contact.email }}</a>
      <template v-if="contact.email2">
        <br><a @click="openMailClient"> {{ contact.email2 }}</a>
      </template>
      <template v-if="contact.email3">
        <br><a @click="openMailClient"> {{ contact.email3 }}</a>
      </template>
    </span>
</template>

<script>
import EventBus from '@/components/iam/bus'

export default {
  name: 'Email',
  props: ['contact', 'type', 'callback', 'emailContext'],
  methods: {
    openMailClient () {
      EventBus.$emit('mailclient-prefill-and-show', {
        contacts: [{
          type: this.type,
          contact: this.contact
        }],
        context: this.emailContext,
        callback: this.callback
      })
    }
  }
}
</script>
