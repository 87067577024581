<template>
  <IAMModal ref="modal" title="Selecteer medewerker">
    <CollaboratorSearch @query="searchForCollaborators"></CollaboratorSearch>
    <p>Met welke medewerker wil je deze lead delen?</p>
    <transition enter-active-class="animated fadeInDown">
      <img src="@/assets/img/loader.gif" v-if="querying">
    </transition>
    <div class="row">

      <transition-group enter-active-class="animated fadeInDown">
        <CollaboratorDisplayBox v-for="collaborator in shareableCollaborators"
                                v-bind:collaborator="collaborator"
                                v-bind:key="collaborator.id"
                                @select="shareWithCollaborator"
        ></CollaboratorDisplayBox>
      </transition-group>
    </div>
  </IAMModal>
</template>

<script>
import $ from 'jquery'
import { getCollaborators } from '@/services/organization'

import IAMModal from '@/components/iam/Modal'
import CollaboratorSearch from '@/components/organization/CollaboratorSearch'
import CollaboratorDisplayBox from '@/components/organization/CollaboratorDisplayBox'

export default {
  name: 'ShareWithCollaborator',
  components: {
    IAMModal,
    CollaboratorSearch,
    CollaboratorDisplayBox
  },
  data: function () {
    return {
      querying: false,
      shareableCollaborators: []
    }
  },
  methods: {
    async show () {
      if (!this.shareableCollaborators.length) {
        this.querying = true
        const response = await getCollaborators()
        this.querying = false
        this.shareableCollaborators = response.data?.results
      }
      this.$refs.modal.show()
    },
    hide: function () {
      this.$refs.modal.hide()
    },
    shareWithCollaborator: function (collaborator) {
      this.$emit('share-with-collaborator', collaborator)
    },
    searchForCollaborators: function (query) {
      const v = this
      v.shareableCollaborators = []
      v.querying = true

      $.ajax({
        type: 'GET',
        url: '/api/collaborator?query=' + query,
        success: function (data) {
          v.shareableCollaborators = data.results
          v.querying = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
