var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Nieuw bod","max-width":"tw-max-w-xl"},on:{"hide":_vm.onHide}},[_c('FormulateForm',{attrs:{"name":"createBidForm"},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"contact","multiple":true,"name":"contacts","label":"Kandidaat-kopers","placeholder":"Selecteer contacten","validation":"required","data-lpignore":"true","outer-class":"tw-my-0"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var label = ref.label;
var id = ref.id;
var classes = ref.classes;
return [_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('label',{staticClass:"formulate-label",class:classes.label,attrs:{"for":id},domProps:{"textContent":_vm._s(label)}}),_c('button',{staticClass:"link tw-font-semibold",attrs:{"type":"button","title":"Nieuwe eigenaar"},on:{"click":_vm.showContactCreateModal}},[_c('i',{staticClass:"far fa-user-plus"}),_vm._v(" Nieuw contact ")])])]}}],null,true)}),_c('FormulateInput',{attrs:{"type":"number","name":"amount","label":"Bod","placeholder":"Bod","lang":"nl","step":"0.01","validation":"bail|required|number|min:0","data-lpignore":"true","outer-class":"tw-my-0"}}),_c('FormulateInput',{attrs:{"type":"date","name":"bid_date","label":"Biedingsdatum","placeholder":"YYYY-MM-DD","value":_vm.today.date,"validation":"bail|required|date:YYYY-MM-DD","data-lpignore":"true","outer-class":"tw-my-0"}}),_c('FormulateInput',{attrs:{"type":"date","name":"valid_until_date","label":"Geldig tot","placeholder":"YYYY-MM-DD","min":_vm.values.bid_date,"validation":[
          ['bail'],
          ['optional'],
          ['date', 'YYYY-MM-DD'],
          ['after', _vm.values.bid_date]
        ],"data-lpignore":"true","outer-class":"tw-my-0"}})],1),_c('FormulateInput',{attrs:{"type":"textarea","name":"comment","placeholder":"Opmerking","input-class":['tw-h-24']},scopedSlots:_vm._u([{key:"label",fn:function(ref){
        var id = ref.id;
        var classes = ref.classes;
return [_c('label',{class:classes.label,attrs:{"for":id}},[_vm._v(" Opmerking ")]),_c('Tooltip',{staticClass:"tw-ml-2 tw-inline-block",scopedSlots:_vm._u([{key:"popper",fn:function(){return [_vm._v(" Geef bijkomende info mee: roerende goederen, excl of incl garage, berging, staanplaats,... ")]},proxy:true}],null,true)},[_c('i',{staticClass:"fas fa-info-circle"})])]}}],null,true)}),_c('div',{staticClass:"tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"select","name":"suspensive_condition","label":"Opschortende voorwaarde","placeholder":"Selecteer een voorwaarde","options":_vm.suspensiveConditions,"outer-class":"tw-my-0"}}),_c('FormulateInput',{attrs:{"type":"file","name":"contact_confirmation_file","label":"Bijlage uploaden (bieder)","uploader":_vm.uploadFile,"upload-area-mask-class":['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5'],"file-class":['tw-h-10 !tw-mt-1'],"element-class":"formulate-input-element formulate-input-element--file","wrapper-class":"formulate-input-wrapper","outer-class":"formulate-input !tw-my-0"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
        var id = ref.id;
        var classes = ref.classes;
return [_c('label',{class:classes.label,attrs:{"for":id}},[_vm._v(" Bijlage uploaden (bieder) ")]),_c('Tooltip',{staticClass:"tw-ml-2 tw-inline-block",scopedSlots:_vm._u([{key:"popper",fn:function(){return [_vm._v(" Voeg de mail of de (tegen)getekende aankoopbelofte toe ")]},proxy:true}],null,true)},[_c('i',{staticClass:"fas fa-info-circle"})])]}},{key:"file",fn:function(context){return [_c('BaseFileUploadDisplay',_vm._b({attrs:{"show-remove-file":true}},'BaseFileUploadDisplay',context,false))]}}],null,true)})],1),_c('FormulateInput',{attrs:{"type":"checkbox","name":"is_confirmed_in_writing","label":"Dit bod is schriftelijk ontvangen"}}),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading}},[_c('i',{class:[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-gavel'
          ]}),_vm._v(" Bod registreren ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}}),_c('ContactCreateModal',{ref:"contactCreateModal",attrs:{"redirect":false},on:{"success":_vm.newOwnerCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }