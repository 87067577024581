<template>
  <div class="tw-p-4 tw-shadow-card tw-rounded">
    <div class="tw-flex tw-flex-wrap tw-gap-4 tw-justify-between">
      <div>
        <strong>
          <router-link :to="{ name: 'ContactDetails', params: { id: contact.id } }">
            {{ contact.display_name }}
          </router-link>
        </strong>
        <span class="tw-uppercase tw-text-xs">
          ({{ contact.language }})
        </span>
      </div>
      <div class="tw-flex tw-gap-1 tw-flex-wrap">
        <gdpr-component text="GDPR" :contact="contact" />
        <ContactDuplicateCounter
          :as-label="true"
          :contact="contact"
          counter-prefix="Duplicate contacten:"
        />
        <ContactNoShowsCounter :contact-id="contact.id" />
      </div>
    </div>

    <div class="tw-mt-2 tw-flex tw-flex-wrap tw-gap-y-2 tw-gap-x-4">
      <div v-if="contact.phone">
        <i class="fa fa-phone" />
        <a :href="`tel:${contact.phone}`">
          {{ contact.phone }}
        </a>
        <br>
      </div>
      <div v-if="contact.mobile">
        <i class="fa fa-mobile" />
        <a :href="`tel:${contact.mobile}`">
          {{ contact.mobile }}
        </a>
        <br>
      </div>
      <div v-if="contact.email">
        <i class="fa fa-envelope" />
        <Email
          :contact="contact"
          :type="type"
          :emailContext="emailContext"
        >
          {{ contact.email }}
        </Email>
      </div>
    </div>

    <div v-if="type === 'candidate' && contact.notes" class="tw-mt-2">
      <i class="fa fa-comment tw-mr-1" />{{ notesAbbreviated }}
      <button
        v-if="showShowAllNotesButton && !allNotesShown"
        @click="showAllNotes"
        class="tw-font-bold"
      >
        Toon alles
      </button>
    </div>
  </div>
</template>

<script>
import Email from '@/components/iam/Email'
import GdprComponent from '@/components/iam/gdprComponent'
import ContactDuplicateCounter from '@/components/contacts/ContactDuplicateCounter'
import ContactNoShowsCounter from '@/components/contacts/ContactNoShowsCounter'

import { USER_LANG_OPTIONS } from '@/utils/helpers'

export default {
  name: 'ContactDisplayBox',
  components: {
    ContactDuplicateCounter,
    ContactNoShowsCounter,
    GdprComponent,
    Email
  },
  props: {
    contact: {
      default: null
    },
    type: {
      type: String,
      default: 'to'
    },
    emailContext: {
      default: null
    }
  },
  data () {
    return {
      allNotesShown: false
    }
  },
  methods: {
    showAllNotes () {
      this.allNotesShown = true
    }
  },
  computed: {
    contactLang () {
      return USER_LANG_OPTIONS[this.contact.language]
    },
    showShowAllNotesButton () {
      return this.contact.notes.length > 75
    },
    notesAbbreviated () {
      if (this.allNotesShown || this.contact.notes.length < 75) return this.contact.notes
      return `${this.contact.notes.slice(0, 75)} ...`
    }
  }
}
</script>
