<template>
  <BaseModal ref="modal" title="Activiteit wijzigen" max-width="tw-max-w-3xl">
    <FormulateForm
      v-if="activity"
      #default="{ isLoading }"
      v-model="activity"
      name="editActivityForm"
      @submit="submit"
    >
      <div class="tw-my-4">
        <label class="formulate-label">Tijd</label>
        <DateRangePicker
          ref="dateRange"
          :old-style="false"
          @hook:mounted="setDates"
        />
      </div>

      <FormulateInput
        v-if="!isFinished"
        type="textarea"
        name="description"
        label="Beschrijving"
      />

      <template v-if="isFinished">
        <FormulateInput
          type="textarea"
          name="feedback"
          label="Feedback (intern)"
        />

        <FormulateInput
          type="textarea"
          name="feedback_reporting"
          label="Feedback (rapportering)"
        />
      </template>

      <FormulateErrors class="tw-text-right" />
      <FormulateInput
        type="submit"
        title="Opslaan"
        :disabled="isLoading"
        outer-class="tw-float-right tw-mb-4"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Opslaan
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { format } from 'date-fns'

import DateRangePicker from '@/components/iam/DateRangePicker'

import { getLeadActivity, updateActivity } from '@/services/sales'

export default {
  name: 'EditActivityModal',
  components: {
    DateRangePicker
  },
  data () {
    return {
      activity: null
    }
  },
  computed: {
    isFinished () {
      // 2 = cancelled
      // 3 = aborted
      // 4 = finished
      return [2, 3, 4].includes(this.activity?.status)
    }
  },
  methods: {
    async show (activityId) {
      const response = await this.loadActivity(activityId)
      this.$refs.modal.show()
      return response
    },
    hide () {
      this.$refs.modal.hide()
      this.activity = null
    },
    setDates () {
      const { start_date, end_date } = this.activity
      const startDate = start_date ? format(new Date(start_date), 'dd-MM-yyyy HH:mm') : null
      const endDate = end_date ? format(new Date(end_date), 'dd-MM-yyyy HH:mm') : null
      this.$refs.dateRange.setStartDate(startDate)
      this.$refs.dateRange.setEndDate(endDate)
    },
    async loadActivity (activityId) {
      const response = await getLeadActivity(activityId)
      this.activity = response.data
      return response
    },
    async submit (data) {
      try {
        const { description, id, feedback, feedback_reporting } = data
        const start_date = this.$refs.dateRange.getStartDate()
        const end_date = this.$refs.dateRange.getEndDate()
        const payload = {
          start_date,
          end_date,
          description,
          feedback,
          feedback_reporting
        }

        const response = await updateActivity(id, payload)
        const messagesWereDeleted = response.data?.messages_were_deleted
        if (messagesWereDeleted) {
          const activity = {
            start_date: response.data.start_date,
            end_date: response.data.end_date,
            ...data
          }
          this.$emit('messages-were-deleted', { type: 'SMS', activity })
        }
        this.$emit('activity-changed')
        this.hide()
        return response
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'editActivityForm')
      }
    }
  }
}
</script>

<style>

</style>
