<template>
  <div>
    <details v-if="survey.collaborator_comments.length">
      <summary class="tw-cursor-pointer tw-italic">
        <slot name="summary">
          Opmerkingen van de medewerker ({{ survey.collaborator_comments.length }})
        </slot>
      </summary>
      <div
        v-for="{ comment, collaborator, id } in survey.collaborator_comments"
        :key="id"
        class="tw-my-2 tw-flex tw-gap-2 tw-items-start tw-max-w-md"
      >
        <div class="tw-flex-grow">
          <h4 class="tw-m-0">
            {{ collaborator.first_name }} {{ collaborator.last_name }}
          </h4>
          <p class="tw-italic tw-my-0">
            {{ comment }}
          </p>
        </div>
        <!-- We use tw-mt-4 to align the edit button with the comment itself -->
        <button
          v-if="collaborator.id === collaboratorId"
          type="button"
          title="Opmerking bewerken"
          :class="[
            'tw-mt-4 tw-px-1 tw-py-0.5 tw-text-xs tw-rounded tw-border tw-border-success tw-text-success',
            'hover:tw-bg-success hover:tw-text-white'
          ]"
          @click="editComment(id, comment)"
        >
          <i class="far fa-pencil" />
        </button>
      </div>
    </details>

    <button
      v-if="!survey.collaborator_comments.find(({ collaborator }) => collaborator.id === collaboratorId)"
      type="button"
      title="Voeg een nieuwe opmerking toe"
      class="link tw-mt-2"
      @click="addComment(survey.id)"
    >
      <i class="far fa-comment-plus tw-mr-1" />
      Voeg een nieuwe opmerking toe
    </button>
    <BaseModal ref="commentModal" title="Opmerking">
      <FormulateForm
        #default="{ isLoading }"
        v-model="commentForm"
        name="surveyCommentForm"
        @submit="submitComment"
      >
        <FormulateInput
          type="textarea"
          name="comment"
          label="Ondernomen acties door Dewaele"
          placeholder="Ondernomen acties door Dewaele"
          outer-class="tw-my-0"
        />

        <FormulateErrors class="tw-text-right" />

        <div class="tw-flex tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            outer-class="tw-mt-4"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
        </div>
      </FormulateForm>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createSurveyComment, updateSurveyComment } from '@/services/surveys'

export default {
  name: 'SurveysAddEditComment',
  props: {
    survey: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters('account', ['collaborator']),

    collaboratorId () {
      // ID of the logged in collaborator.
      return this.collaborator.id
    }
  },
  data () {
    return {
      commentForm: {}
    }
  },
  methods: {
    addComment (surveyId) {
      this.commentForm = { surveyId }
      this.$refs.commentModal.show()
      return surveyId
    },
    editComment (commentId, comment) {
      this.commentForm = {
        comment,
        commentId
      }
      this.$refs.commentModal.show()
      return commentId
    },

    async submitComment (data) {
      try {
        const { comment, surveyId, commentId } = data
        const response = commentId
          ? await updateSurveyComment(commentId, { comment })
          : await createSurveyComment(surveyId, { comment })
        this.$emit('submitted')
        this.$refs.commentModal.hide()
        this.commentForm = {}
        return response
      } catch (error) {
        this.$formulate.handle(error, 'surveyCommentForm')
      }
    }
  }
}
</script>
