var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Biedingen","max-width":"tw-max-w-7xl"},on:{"hide":_vm.onHide}},[_c('DataTable',_vm._b({staticClass:"tw--m-5 tw-shadow-none",attrs:{"loading":_vm.loading,"headers":_vm.headers,"infinite-scroll":true},on:{"infiniteLoad":_vm.infiniteLoad},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"tw-flex tw-items-center tw-gap-2"},[_c('button',{staticClass:"action tw-bg-success tw-mr-0",attrs:{"type":"button","title":"Nieuw bod","disabled":_vm.newBidModalOpening},on:{"click":_vm.showNewBidModal}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Nieuw bod ")]),_c('Tooltip',{staticClass:"tw-inline-block",scopedSlots:_vm._u([{key:"popper",fn:function(){return [_vm._v(" Geef elk bod in - indien éénzelfde kandidaat meerdere biedingen doet, voeg deze dan apart in (niet wijzigen). Maw 2e bod = nieuwe bodregistratie. ")]},proxy:true}])},[_c('i',{staticClass:"fas fa-info-circle"})])],1)]},proxy:true},{key:"item-info",fn:function(ref){
var item = ref.item;
return [_c('Tooltip',{scopedSlots:_vm._u([{key:"popper",fn:function(){return [(item.created_by)?_c('p',[_vm._v(" Aangemaakt door "),_c('b',[_vm._v(_vm._s(item.created_by.first_name))]),_vm._v(" op "),_c('b',[_vm._v(_vm._s(_vm._f("date-day")(item.created_on)))])]):_vm._e(),(item.updated_by)?_c('p',[_vm._v(" Gewijzigd door "),_c('b',[_vm._v(_vm._s(item.updated_by.first_name))]),_vm._v(" op "),_c('b',[_vm._v(_vm._s(_vm._f("date-day")(item.updated_on)))])]):_vm._e()]},proxy:true}],null,true)},[_c('i',{staticClass:"fas fa-info-circle"})])]}},{key:"item-property_bid_contacts",fn:function(ref){
var item = ref.item;
return [(item.property_bid_contacts.length)?_c('ul',{staticClass:"tw-list-disc tw-ml-4"},_vm._l((item.property_bid_contacts),function(object){return _c('li',{key:object.id},[(object.contact)?_c('router-link',{attrs:{"to":{ name: 'ContactDetails', params: { id: object.contact.id } },"target":"_blank"}},[_vm._v(" "+_vm._s(object.contact.display_name)+" ")]):_vm._e()],1)}),0):_c('span',[_vm._v("-")])]}},{key:"item-match",fn:function(ref){
var item = ref.item;
return [(item.match)?_c('router-link',{attrs:{"to":{ name: 'LeadDetails',  params: { id: item.match } },"target":"_blank"}},[_c('i',{staticClass:"fa fa-link"})]):_c('span',[_vm._v("-")])]}},{key:"item-amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currency(item.amount))+" ")]}},{key:"item-suspensive_condition",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.suspensive_condition ? item.suspensive_condition.name : '-')+" ")]}},{key:"item-contact_confirmation_file",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-grid tw-place-items-center"},[(item.contact_confirmation_file)?_c('a',{staticClass:"link",attrs:{"href":item.contact_confirmation_file.url,"title":item.contact_confirmation_file.filename,"target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"far fa-file-pdf tw-text-xl"})]):_c('span',[_vm._v("-")])])]}},{key:"item-is_confirmed_in_writing",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-grid tw-place-items-center"},[_c('FormulateInput',{attrs:{"id":("bid_" + (item.id) + "_is_confirmed_in_writing"),"type":"checkbox","name":"is_confirmed_in_writing","outer-class":"tw-my-0"},on:{"input":function($event){return _vm.manuallyConfirmBid(item.id, $event)}},model:{value:(item.is_confirmed_in_writing),callback:function ($$v) {_vm.$set(item, "is_confirmed_in_writing", $$v)},expression:"item.is_confirmed_in_writing"}})],1)]}},{key:"item-is_accepted",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-grid tw-place-items-center"},[_c('FormulateInput',{attrs:{"id":("bid_" + (item.id) + "_confirm"),"type":"checkbox","name":"confirm","outer-class":"tw-my-0","disabled":_vm.hasAcceptedBid},on:{"input":function($event){return _vm.showBidConfirmModal(item)}},model:{value:(item.is_accepted),callback:function ($$v) {_vm.$set(item, "is_accepted", $$v)},expression:"item.is_accepted"}})],1)]}},{key:"item-actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex"},[(!item.is_accepted)?_c('button',{staticClass:"action tw-bg-danger",attrs:{"title":"Bod verwijderen","type":"button"},on:{"click":function($event){return _vm.removeBid(item.id)}}},[_c('i',{staticClass:"far fa-trash-alt"})]):_vm._e()])]}}])},'DataTable',_vm.bids,false)),_c('PropertyBidCreateModal',{ref:"createNewBid",attrs:{"lead-id":_vm.leadId,"property-id":_vm.propertyId},on:{"bidCreated":_vm.loadTableData}}),_c('PropertyBidConfirmEditModal',{ref:"confirmEditBid",attrs:{"property-id":_vm.propertyId},on:{"bidAccepted":_vm.handleBidAccepted,"bidUpdated":_vm.loadTableData,"hideWithoutAcceptance":_vm.loadTableData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }