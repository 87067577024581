var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Aanvaarding bod","max-width":"tw-max-w-xl"},on:{"hide":_vm.hide}},[_c('div',{staticClass:"tw-flex tw-justify-between tw-gap-4 tw-flex-wrap-reverse"},[_c('div',[(_vm.bid.created_by)?_c('p',[_vm._v(" Aangemaakt door "),_c('b',[_vm._v(_vm._s(_vm.bid.created_by.first_name))]),_vm._v(" op "),_c('b',[_vm._v(_vm._s(_vm._f("date-day")(_vm.bid.created_on)))])]):_vm._e(),(_vm.bid.updated_by)?_c('p',[_vm._v(" Gewijzigd door "),_c('b',[_vm._v(_vm._s(_vm.bid.updated_by.first_name))]),_vm._v(" op "),_c('b',[_vm._v(_vm._s(_vm._f("date-day")(_vm.bid.updated_on)))])]):_vm._e()]),_c('FormulateInput',{attrs:{"type":"toggle","debounce":"","name":"edit_mode","label":"Bewerking","outer-class":"tw-my-0"},model:{value:(_vm.editMode),callback:function ($$v) {_vm.editMode=$$v},expression:"editMode"}})],1),_c('span',{staticClass:"formulate-label"},[_vm._v("Kandidaat-kopers")]),(_vm.bid.property_bid_contacts.length)?_c('ul',_vm._l((_vm.bid.property_bid_contacts),function(object){return _c('li',{key:object.id},[(object.contact)?_c('router-link',{attrs:{"to":{ name: 'ContactDetails', params: { id: object.contact.id } },"target":"_blank"}},[_vm._v(" "+_vm._s(object.contact.display_name)+" ")]):_vm._e()],1)}),0):_vm._e(),(!_vm.editMode)?_c('div',{staticClass:"formulate-label tw-my-4"},[_vm._v(" Bod: "+_vm._s(_vm.currency(_vm.bid.amount))+" ")]):_vm._e(),_c('FormulateForm',{attrs:{"name":"confirmEditBidForm"},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"date","name":"accepted_date","label":"Datum aanvaarding","placeholder":"YYYY-MM-DD","value":_vm.today.date,"validation":_vm.editMode ? '' : 'bail|required|date:YYYY-MM-DD',"data-lpignore":"true","outer-class":"tw-my-4 tw-grid md:tw-grid-cols-2"}}),_c('FormulateInput',{attrs:{"type":"file","name":"owner_acceptance_file","label":"Bijlage uploaden (aanvaarding eigenaar)","uploader":_vm.uploadFile,"upload-area-mask-class":['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5'],"file-class":['tw-h-10 !tw-mt-1'],"element-class":"formulate-input-element formulate-input-element--file","wrapper-class":"formulate-input-wrapper","outer-class":"formulate-input"},scopedSlots:_vm._u([{key:"file",fn:function(context){return [_c('BaseFileUploadDisplay',_vm._b({attrs:{"show-remove-file":true}},'BaseFileUploadDisplay',context,false))]}}],null,true)}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.editMode)?_c('div',[_c('div',{staticClass:"tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"number","name":"amount","label":"Bod","placeholder":"Bod","lang":"nl","step":"0.01","validation":"bail|required|number","keep-model-data":"true","data-lpignore":"true","outer-class":"tw-my-0"}}),_c('FormulateInput',{attrs:{"type":"date","name":"bid_date","label":"Biedingsdatum","placeholder":"YYYY-MM-DD","validation":"bail|required|date:YYYY-MM-DD","keep-model-data":"true","data-lpignore":"true","outer-class":"tw-my-0"}}),_c('FormulateInput',{attrs:{"type":"date","name":"valid_until_date","label":"Geldig tot","placeholder":"YYYY-MM-DD","min":_vm.bid.bid_date,"validation":[
              ['bail'],
              ['optional'],
              ['date', 'YYYY-MM-DD'],
              ['after', _vm.bid.bid_date]
            ],"keep-model-data":"true","data-lpignore":"true","outer-class":"tw-my-0"}})],1),_c('FormulateInput',{attrs:{"type":"textarea","name":"comment","label":"Opmerking","placeholder":"Opmerking","keep-model-data":"true","input-class":['tw-h-24']}}),_c('div',{staticClass:"tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"select","name":"suspensive_condition","label":"Opschortende voorwaarde","placeholder":"Selecteer een voorwaarde","options":_vm.suspensiveConditions,"keep-model-data":"true","outer-class":"tw-my-0"}}),_c('FormulateInput',{attrs:{"type":"file","name":"contact_confirmation_file","label":"Bijlage uploaden (bieder)","keep-model-data":"true","uploader":_vm.uploadFile,"upload-area-mask-class":['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5'],"file-class":['tw-h-10 !tw-mt-1'],"element-class":"formulate-input-element formulate-input-element--file","wrapper-class":"formulate-input-wrapper","outer-class":"formulate-input !tw-my-0"},scopedSlots:_vm._u([{key:"file",fn:function(context){return [_c('BaseFileUploadDisplay',_vm._b({attrs:{"show-remove-file":true}},'BaseFileUploadDisplay',context,false))]}}],null,true)})],1),_c('FormulateInput',{attrs:{"type":"checkbox","name":"is_confirmed_in_writing","label":"Dit bod is schriftelijk ontvangen","keep-model-data":"true"}})],1):_vm._e()]),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"input-class":[_vm.editMode ? 'tw-bg-gray-500' : '']}},[_c('i',{class:[
            'fas tw-mr-2',
            isLoading
              ? 'fa-spinner-third fa-spin'
              : (_vm.editMode ? 'fa-save' : 'fa-badge-check')
          ]}),_vm._v(" "+_vm._s(_vm.editMode ? 'Opslaan' : 'Bod aanvaard door eigenaar')+" ")])],1)]}}]),model:{value:(_vm.bid),callback:function ($$v) {_vm.bid=$$v},expression:"bid"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }