<template>
  <span>{{ object.street }} {{ object.number }} {{ object.box }}</span>
</template>

<script>
export default {
  name: 'ContactAddress',
  props: ['value', 'object']
}
</script>

<style scoped>

</style>
